import {lazy, Suspense} from 'react';
import {Outlet, Navigate, useRoutes} from 'react-router-dom';

import MainLayout from 'src/layouts/main';
import {SplashScreen} from 'src/components/loading-screen';

import {authRoutes} from './auth';
import {errorRoutes} from './error';
import {commonRoutes} from './common';
import {challengesRoutes} from './challenge';
import {solutionsRoutes} from './solution';
import {accountRoutes} from './account';
import {contactRoutes} from './contact';

// ----------------------------------------------------------------------

const IndexPage = lazy(() => import('src/pages/home'));

export default function Router() {
  return useRoutes([
    {
      element: (
        <MainLayout>
          <Suspense fallback={<SplashScreen/>}>
            <Outlet/>
          </Suspense>
        </MainLayout>
      ),
      children: [
        {element: <IndexPage/>, index: true},

        ...challengesRoutes,

        ...solutionsRoutes,

        ...accountRoutes,

        ...contactRoutes
      ],
    },

    ...authRoutes,

    ...errorRoutes,

    ...commonRoutes,

    {path: '*', element: <Navigate to="/404" replace/>},
  ]);
}
