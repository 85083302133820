import { lazy } from 'react';

const IndexPage = lazy(() => import('src/pages/contact/contact'));

export const contactRoutes = [
  {
    children: [
      {
        path: 'contact',
        children: [
          { element: <IndexPage />, index: true },
        ],
      },
    ],
  },
];
