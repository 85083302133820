// ----------------------------------------------------------------------

export const paths = {
  // Auth
  login: '/auth/login',
  registerCover: '/auth/register-cover',
  registerBackground: '/auth/register-background',
  registerIllustration: '/auth/register-illustration',
  forgotPassword: '/auth/forgot-password',
  verify: '/auth/verify',
  // Common
  maintenance: '/maintenance',
  comingsoon: '/coming-soon',
  pricing01: '/pricing-01',
  pricing02: '/pricing-02',
  payment: '/payment',
  support: '/support',
  page404: '/404',
  page500: '/500',
  // Account
  account: {
    root: '/account',
  },
  // Contact
  contact: {
    root: '/contact',
  },
  // Challenges
  challenges: {
    root: '/challenges',
    challenge: '/challenges/challenge',
    challengesStarted: '/challenges/solutions-started',
    carousel: '/components/carousel',
    countUp: '/components/count-up',
    form: '/components/form-validation',
    icons: '/components/icons',
    image: '/components/image',
    label: '/components/label',
    lightbox: '/components/lightbox',
    markdown: '/components/markdown',
    megaMenu: '/components/mega-menu',
    navigation: '/components/navigation-bar',
    scroll: '/components/scroll',
    player: '/components/player',
    textMaxLine: '/components/text-max-line',
  },
  // Solutions
  solutions: {
    root: '/solutions',
    solutions: '/solutions/list-solutions',
  },
  // Others
  pages: '/pages',
  docs: 'https://zone-docs.vercel.app',
  license: 'https://material-ui.com/store/license/#i-standard-license',
  minimalStore: 'https://material-ui.com/store/items/minimal-dashboard',
  zoneStore: 'https://mui.com/store/items/zone-landing-page/',
  figmaPreview:
    'https://www.figma.com/file/X6OyiGHF8dnTk3aT38P0oN/%5BPreview%5D-Zone_Web.30.03.23?node-id=0-1',
};
