import {paths} from 'src/routes/paths';

// ----------------------------------------------------------------------

export const pageLinks = [
  {
    order: '1',
    subheader: 'Front-end Specialist',
    cover: '/assets/images/menu/menu_marketing.jpg',
    items: [
      {title: 'Contato', path: paths.contact.root},
    ],
  },
  {
    order: '2',
    subheader: 'Explorar',
    items: [
      {title: 'Desafios', path: paths.challenges.root},
      {title: 'Soluções', path: paths.solutions.root},
    ],
  },
];

export const navConfig = [
  {title: 'Home', path: '/'},
  {title: 'Desafios', path: paths.challenges.root},
  {title: 'Soluções', path: paths.solutions.root},
  {title: 'Contato', path: paths.contact.root},
];
