import { lazy } from 'react';

const IndexPage = lazy(() => import('src/pages/challenge/challenges'));
const ChallengePage = lazy(() => import('src/pages/challenge/challenge'));
const ChallengesStarted = lazy(() => import('src/pages/challenge/challenges-started'));

export const challengesRoutes = [
  {
    children: [
      {
        path: 'challenges',
        children: [
          { element: <IndexPage />, index: true },
          { path: 'challenge/:id', element: <ChallengePage />},
          { path: '/challenges/solutions-started', element: <ChallengesStarted />}
        ],
      },
    ],
  },
];
