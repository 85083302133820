import {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import {useTheme} from '@mui/material/styles';
import Container from '@mui/material/Container';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Logo from 'src/components/logo';
import {bgBlur} from 'src/theme/css';
import {paths} from 'src/routes/paths';
import {useOffSetTop} from 'src/hooks/use-off-set-top';
import {useResponsive} from 'src/hooks/use-responsive';

import {HEADER} from '../config-layout';
import HeaderShadow from '../common/header-shadow';
import SettingsButton from '../common/settings-button';

import NavMobile from './nav/mobile';
import NavDesktop from './nav/desktop';
import {navConfig} from './config-navigation';

import api from 'src/services/api'
import Iconify from "../../components/iconify";
import IconButton from "@mui/material/IconButton";

type Props = {
  headerOnDark: boolean;
};

export default function Header({headerOnDark}: Props) {
  const navigate = useNavigate()
  const theme = useTheme();
  const offset = useOffSetTop();
  const mdUp = useResponsive('up', 'md');

  const [name, setName] = useState(null)
  const [userId, setUserId] = useState(null)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    getDataProfile()
  }, [])

  const getDataProfile = async () => {
    const token = localStorage.getItem('token');

    if (token) {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      try {
        const response = await api.get("/me", {headers})

        localStorage.setItem('id', response.data.user.id)
        setName(response.data.user.name)
        setUserId(response.data.user.id)
      } catch (error) {
        console.log(error)
      }
    }
  }
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    navigate("/")
    localStorage.removeItem("token")
    window.location.reload()
  }

  const handleMyChallenges = () => {
    navigate('/challenges/solutions-started')
    handleClose()
  }

  const handleAccount = () => {
    navigate(`/account/${userId}`)
    handleClose()
  }

  return (
    <AppBar>
      <Toolbar
        disableGutters
        sx={{
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_DESKTOP,
          },
          transition: theme.transitions.create(['height', 'background-color'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...(headerOnDark && {
            color: 'common.white',
          }),
          ...(offset && {
            ...bgBlur({color: theme.palette.background.default}),
            color: 'text.primary',
            height: {
              md: HEADER.H_DESKTOP - 16,
            },
          }),
        }}
      >
        <Container
          sx={{height: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}
        >
          <Box sx={{lineHeight: 0, position: 'relative'}}>
            <Logo/>
          </Box>

          {mdUp && <NavDesktop data={navConfig}/>}

          <Stack spacing={2} direction="row" alignItems="center" justifyContent="flex-end">
            <Stack spacing={1} direction="row" alignItems="center">
              <SettingsButton/>
            </Stack>
            {name && (
              <div>
                <IconButton
                  size="small"
                  color="inherit"
                  onClick={handleClick}
                  sx={{p: 1}}
                >
                  <Iconify icon="carbon:user" width={18}/>
                </IconButton>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem sx={{minWidth: 200}} onClick={handleMyChallenges}>Meus desafios</MenuItem>
                  <MenuItem sx={{minWidth: 200}} onClick={handleAccount}>Conta</MenuItem>
                  <MenuItem onClick={handleLogout}>Sair</MenuItem>
                </Menu>
              </div>
            )}

            {mdUp && !name && (
              <Button
                variant="contained"
                color="inherit"
                href={paths.login}
                rel="noopener"
                style={{padding: '.5rem 1.5rem'}}
              >
                Entrar
              </Button>
            )}
          </Stack>

          {!mdUp && <NavMobile data={navConfig}/>}
        </Container>
      </Toolbar>

      {offset && <HeaderShadow/>}
    </AppBar>
  );
}
