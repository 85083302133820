import { lazy } from 'react';

const IndexPage = lazy(() => import('src/pages/account/account'));

export const accountRoutes = [
  {
    children: [
      {
        path: 'account/:id',
        children: [
          { element: <IndexPage />, index: true },
        ],
      },
    ],
  },
];
