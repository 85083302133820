import { lazy } from 'react';

const IndexPage = lazy(() => import('src/pages/list-solutions/list-solution'));
const CreateSolutions = lazy(() => import('src/pages/solution/solution'));
const ListSolutions = lazy(() => import('src/pages/solution/solution'));

export const solutionsRoutes = [
  {
    children: [
      {
        path: 'solutions',
        children: [
          { element: <IndexPage />, index: true },
          { path: '/solutions/:challengeId', element: <CreateSolutions />},
          { path: '/solutions/list-solutions', element: <ListSolutions />},
        ],
      },
    ],
  },
];
